<template>
  <div>
    <div class="selector-form">
      <span>Select Analytics For:</span>
      <form action="">
        <select
          class="selectorField"
          @change="getAnalyticsFor"
          v-model="analyticsFor"
        >
          <option value="direct">Direct Analytics</option>
          <option value="downline">Downline Analytics</option>
        </select>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "RepSelector",
  data() {
    return {
      analyticsFor: "",
    };
  },
  methods: {
    getAnalyticsFor() {
      let routeName = this.$route.name;
      if (routeName == "RepAnalytics" && this.analyticsFor == "downline") {
        this.$router.push({ name: "RepAnalyticsDownline" });
      }
      if (
        routeName == "RepAnalyticsDownline" &&
        this.analyticsFor == "direct"
      ) {
        this.$router.push({ name: "RepAnalytics" });
      }
    },
  },
  created() {
    if (this.$route.name == "RepAnalytics") {
      this.analyticsFor = "direct";
    }
    if (this.$route.name == "RepAnalyticsDownline") {
      this.analyticsFor = "downline";
    }
  },
};
</script>
