var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overallInfo-block d-block"
  }, [_c('div', {
    staticClass: "overallInfo-content"
  }, [_c('h3', [_vm._v("Overall Total Policies")]), _c('div', {
    staticClass: "valueDenote"
  }, [_c('ul', [_c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Tier: IO"
    }
  }, [_c('span', {
    staticClass: "value-active"
  }), _vm._v(" IO ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Tier: IS"
    }
  }, [_c('span', {
    staticClass: "value-withdrawn"
  }), _vm._v(" IS ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Tier: IC"
    }
  }, [_c('span', {
    staticClass: "value-termed"
  }), _vm._v(" IC ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Tier: IF"
    }
  }, [_c('span', {
    staticClass: "value-safari"
  }), _vm._v(" IF ")]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Total"
    }
  }, [_c('span', {
    staticClass: "value-total"
  }), _vm._v(" Total ")])])])]), _c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "300",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }