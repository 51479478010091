<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="analytics-report">
        <h5>New Members By Month (Active & Termed)</h5>
        <div class="spline-chart">
          <apexchart
            type="area"
            height="350"
            width="96%"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  data: function () {
    return {
      newMemberInfo: [],
      series: [],
      chartOptions: {},
    };
  },
  methods: {
    async getMonthlyEnrollments() {
      const url = "v2/rep-analytics/members-monthly?downline_report=true";
      await Api.get(url)
        .then((res) => {
          this.newMemberInfo = res.data.data;
          this.series = [
            {
              name: "Active",
              data: this.getActiveData,
            },
            {
              name: "Termed",
              data: this.getTermedData,
            },
            {
              name: "Net",
              data: this.getNetData,
            },
          ];
          this.getChartOptions();
        })
        .catch(()=>{
          this.newMemberInfo = [];
        })
        .finally(() => {
          this.$emit("hide-loader", "dwnewMemberByMonth");
        });
    },
    getChartOptions() {
      this.chartOptions = {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: "2",
        },
        xaxis: {
          type: "category",
          categories: this.getComputedCategory,
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        legend: {
          show: true,
          position: "top",
        },
      };
    },
  },
  computed: {
    getComputedCategory() {
      return this.newMemberInfo.map(
        (item) => item.label + " " + new Date(item.date).getFullYear().toString().substr(-2)
      );
    },
    getActiveData() {
      return this.newMemberInfo
        ? this.newMemberInfo.map((item) => item.active)
        : [];
    },
    getTermedData() {
      return this.newMemberInfo
        ? this.newMemberInfo.map((item) => item.termed)
        : [];
    },
    getNetData() {
      return this.newMemberInfo
        ? this.newMemberInfo.map((item) => item.net)
        : [];
    },
  },
  created() {
    this.getMonthlyEnrollments();
  },
};
</script>