var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "selector-form"
  }, [_c('span', [_vm._v("Select Analytics For:")]), _c('form', {
    attrs: {
      "action": ""
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.analyticsFor,
      expression: "analyticsFor"
    }],
    staticClass: "selectorField",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.analyticsFor = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.getAnalyticsFor]
    }
  }, [_c('option', {
    attrs: {
      "value": "direct"
    }
  }, [_vm._v("Direct Analytics")]), _c('option', {
    attrs: {
      "value": "downline"
    }
  }, [_vm._v("Downline Analytics")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }