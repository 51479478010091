var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper rep-analytics-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('div', {
    staticClass: "page-title split"
  }, [_c('h1', [_vm._v("Rep Analytics")]), _c('rep-selector')], 1), _c('br'), _c('div', {
    staticClass: "downline"
  }, [_vm._m(0), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('DwNewMembersByMonth', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('DwOverallTotals', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('DwOverallTotalMembers', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1)])]), _c('div', {
    staticClass: "row mb-5"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('DwEnrollmentsByMonth', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('DwOverallTotalPolicies', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('DwEnrollmentsTermsOverview', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1)]), _c('div', {
    staticClass: "row mb-3 rep-analytics-third"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_c('DwTotalMembersByIndividualPlan', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('DwTotalActiveByState', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('DwTotalMembersByPlan', {
    on: {
      "hide-loader": _vm.updateFlag
    }
  })], 1)])])])], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h5', [_vm._v("Downline Analytics")])]);
}]

export { render, staticRenderFns }