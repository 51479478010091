<template>
  <div class="page-wrapper rep-analytics-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title split">
        <h1>Rep Analytics</h1>
        <rep-selector />
      </div>
      <br />
      <div class="downline">
        <div class="page-title">
          <h5>Downline Analytics</h5>
        </div>
        <div class="row mb-3">
          <div class="col-md-7">
            <DwNewMembersByMonth @hide-loader="updateFlag" />
          </div>
          <div class="col-md-5">
            <div class="row">
              <DwOverallTotals @hide-loader="updateFlag" />
            </div>
            <br />
            <div class="row">
              <DwOverallTotalMembers @hide-loader="updateFlag" />
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-7">
            <DwEnrollmentsByMonth @hide-loader="updateFlag" />
          </div>
          <div class="col-md-5">
            <DwOverallTotalPolicies @hide-loader="updateFlag" />
          </div>
          <div class="col-12">
            <DwEnrollmentsTermsOverview @hide-loader="updateFlag" />
          </div>
        </div>
        <div class="row mb-3 rep-analytics-third">
          <div class="col-md-7">
            <DwTotalMembersByIndividualPlan @hide-loader="updateFlag" />
          </div>
          <div class="col-md-5">
            <div class="row">
              <DwTotalActiveByState @hide-loader="updateFlag" />
            </div>
            <br />
            <div class="row">
              <DwTotalMembersByPlan @hide-loader="updateFlag" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <member-plan-modal /> -->
  </div>
</template>
<script>
import DwOverallTotals from "./DownlinesAnalytics/DwOverallTotals.vue";
import DwOverallTotalMembers from "./DownlinesAnalytics/DwOverallTotalMembers.vue";
import DwNewMembersByMonth from "./DownlinesAnalytics/DwNewMembersByMonth.vue";
import DwEnrollmentsByMonth from "./DownlinesAnalytics/DwEnrollmentsByMonth.vue";
import DwOverallTotalPolicies from "./DownlinesAnalytics/DwOverallTotalPolicies.vue";
import DwEnrollmentsTermsOverview from "./DownlinesAnalytics/DwEnrollmentsTermsOverview.vue";
import DwTotalMembersByIndividualPlan from "./DownlinesAnalytics/DwTotalMembersByIndividualPlan.vue";
import DwTotalActiveByState from "./DownlinesAnalytics/DwTotalActiveByState.vue";
import DwTotalMembersByPlan from "./DownlinesAnalytics/DwTotalMembersByPlan.vue";
import RepSelector from "./RepSelector.vue";

export default {
  name: "RepAnalytics",
  components: {
    DwOverallTotals,
    DwOverallTotalMembers,
    DwNewMembersByMonth,
    DwEnrollmentsByMonth,
    DwOverallTotalPolicies,
    DwEnrollmentsTermsOverview,
    DwTotalMembersByIndividualPlan,
    DwTotalActiveByState,
    DwTotalMembersByPlan,
    RepSelector,
  },
  data() {
    return {
      repAnalytics: {
        dwenrollmentByMonth: false,
        dwenrollmentTermsOverviewByYear: false,
        dwnewMemberByMonth: false,
        dwtotalActiveByState: false,
        dwtotalMembersByIndividualPlan: false,
        dwtotalMembersByPlan: false,
        dwoverallTotals: false,
        dwoverallTotalPolicies: false,
        dwoverallTotalMembers: false,
      },
      isLoading: true,
      loaderIcon: "",
      breadcrumbItems: [{ title: "Rep Downline Analytics", active: true }],
    };
  },
  methods: {
    updateFlag(key) {
      // console.log("Emitted From", key);
      this.repAnalytics[key] = true;
      this.loadingStatus();
    },
    loadingStatus() {
      if (this.isLoading) {
        this.loaderIcon = this.$loading.show();
        this.isLoading = false;
      }
      let flags = Object.values(this.repAnalytics);
      if (flags.every((flag) => flag === true)) {
        this.loaderIcon.hide();
      }
    },
  },
  created() {
    this.loadingStatus();
  },
};
</script>
<style src="../../assets/css/repanalytics.css"></style>