<template>
  <b-modal id="memberPlan" centered v-model="computedModal">
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="plan-table table-responsive">
      <table class="table" v-if="pageRecords.records.length && keyLabel && valueLabel">
        <thead>
          <tr>
            <th>{{ tableHeadingLeft }}</th>
            <th>No. of Member</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in pageRecords.records" :key="index">
            <td>
              <strong>{{ data[keyLabel] }}</strong>
            </td>
            <td>{{ data[valueLabel] }}</td>
          </tr>
        </tbody>
      </table>
      <p class="text-danger" v-else>
        <span>Currently there is no data</span>
      </p>
    </div>
    <pagination :rows.sync="pageRecords" />
  </b-modal>
</template>
<script>
export default {
  name: "MemberPlanModal",
  props: {
    tableHeadingLeft: {
      required: false,
      default: "Plan Name",
    },
    modal: {
     required:false,
    },
    modalData: {
      required: false,
      default:{},
    },
    keyLabel: {
      required: false,
    },
    valueLabel: {
      required: false,
    },
    perPage: {
      required: false,
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      show: this.modal,
      variants: ["close-btn"],
      pageRecords:this.modalData
    };
  },
  methods: {
    closeModal() {
      this.$emit("update:modalData",this.pageRecords)
      this.$emit("update:modal", false);
    },
  },
  computed: {
    computedModal: {
      get() {
        return this.show;
      },
      set() {
        this.closeModal();  //on clicking outside of modal
      },
    },
  },
};
</script>