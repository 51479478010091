<template>
  <info-chart
    :chartSeries="memberSeries"
    :chartOptions="memberChartOptions"
    title="Overall Total Members"
  />
</template>
<script>
import Api from "@/resource/Api";
import InfoChart from "@/components/RepAnalytics/InfoChart";
export default {
  name: "OverallTotalMembers",
  components: {
    InfoChart,
  },
  data() {
    return {
      memberSeries: [],
      memberChartOptions: {},
    };
  },
  methods: {
    async getMemberStats() {
      const url = `v2/get-agent-member?downline_report=true`;
      await Api.get(url)
        .then((res) => {
          let data = res.data.data;
          let [active, pending, withdrawn, termed] = [
            (data.direct_active ?? 0) + (data.downline_active ?? 0),
            (data.direct_pending ?? 0) + (data.downline_pending ?? 0),
            (data.direct_withdrawn ?? 0) + (data.downline_withdrawn ?? 0),
            (data.direct_termed ?? 0) + (data.downline_termed ?? 0),
          ];
          if (data) {
            this.memberSeries = [active, pending, withdrawn, termed];
            let total = this.memberSeries.reduce(
              (next, current) => next + current,
              0
            );

            const [labels, colors] = [
              ["Active", "Pending", "Withdrawn", "Termed"],
              ["#02BC77", "#2D99FF", "#F29423", "#EA584E"],
            ];
            this.memberChartOptions = this.chartOptions(labels, colors, total);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$emit("hide-loader", "dwoverallTotalMembers");
        });
    },
    chartOptions(labels, colors, total) {
      const options = {
        labels: labels,
        plotOptions: {
          pie: {
            // size: 800,
            customScale: 1.1,
            donut: {
              size: "55%",
            },
          },
        },
        legend: {
          show: false,
        },
        dropShadow: {
          enable: false,
        },
        colors: colors,
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: "8px",
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: function (value) {
              let percent = (value / total) * 100;
              percent = percent.toFixed(2) + "%";
              return `${value}(${percent})`;
            },
          },
        },
      };
      return options;
    },
  },
  created() {
    this.getMemberStats();
  },
};
</script>