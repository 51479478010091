var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "total-members-by-individual-plan"
  }, [_c('div', {
    staticClass: "split-title"
  }, [_c('h5', [_vm._v("Total Members By Individual Carrier")]), _c('a', {
    attrs: {
      "href": "javascript:void(0);"
    },
    on: {
      "click": _vm.openModal
    }
  }, [_vm._v("View All")])]), _vm.memberByPlan['records'] ? _c('div', {
    staticClass: "spline-chart"
  }, [_c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "400",
      "width": "95%",
      "options": _vm.getComputedChartOptions,
      "series": _vm.getComputedSeries
    }
  })], 1) : _c('div', [_c('p', [_vm._v(_vm._s(_vm.dataEmptyMessage))])])]), _vm.showModal ? _c('member-plan-modal', {
    attrs: {
      "modal": _vm.showModal,
      "modalData": _vm.modalData,
      "keyLabel": "carrier_name",
      "valueLabel": "total",
      "tableHeadingLeft": "Carrier Name"
    },
    on: {
      "update:modal": function updateModal($event) {
        _vm.showModal = $event;
      },
      "update:modalData": function updateModalData($event) {
        _vm.modalData = $event;
      },
      "update:modal-data": function updateModalData($event) {
        _vm.modalData = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }