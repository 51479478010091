var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overallInfo-blockWrapper enrollments-tover"
  }, [_c('div', {}, [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('apexchart', {
    attrs: {
      "type": "area",
      "height": "350",
      "width": "100%",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overallInfo-content"
  }, [_c('h3', [_vm._v("Enrollments & Terms Overview (By Year)")])]);
}]

export { render, staticRenderFns }