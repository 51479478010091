<template>
  <div class="overallInfo-blockWrapper">
    <div class="overallInfo-block">
      <div class="overallInfo-content">
        <h3 v-if="title">{{ title }}</h3>
        <span class="total-title" v-show="shortDescription">{{
          shortDescription
        }}</span>
        <div class="valueDenote">
          <ul>
            <li
              v-for="(label, index) in chartOptions.labels"
              :key="index"
              v-b-tooltip.hover
              :title="label + ' ' + chartSeries[index]"
            >
              <span
                class=""
                :style="{ backgroundColor: chartOptions.colors[index] }"
              ></span
              >{{ label }}(
              {{ chartSeries[index] }}
              )
            </li>
          </ul>
        </div>
      </div>
      <apexchart
        class="info-donut"
        :height="chartHeight"
        :type="chartType"
        :options="getComputedChartOptions"
        :series="getComputedChartSeries"
      ></apexchart>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfoChart",
  props: {
    chartOptions: {
      required: true,
    },
    chartSeries: {
      required: true,
    },
    title: {
      required: false,
    },
    shortDescription: {
      required: false,
    },
    chartType: {
      required: false,
      default: "donut",
    },
    chartHeight: {
      required: false,
      default: "80%",
    },
  },
  computed: {
    getComputedChartSeries() {
      return this.chartSeries ? this.chartSeries : [];
    },
    getComputedChartOptions() {
      return this.chartOptions ? this.chartOptions : {};
    },
  },
  created() {},
};
</script>