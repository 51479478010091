<template>
  <div class="row">
    <div class="col-12 mb-3">
      <div class="analytics-report">
        <h5>Enrollments By Month (Last 3 Years)</h5>
        <div class="spline-chart">
          <apexchart
            type="line"
            height="350"
            width="95%"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  data: function () {
    return {
      series: [],
      chartOptions: {},
      enrollmentInfo: "",
    };
  },
  methods: {
    async getEnrollmentReport() {
      const url = "v2/rep-analytics/enrollment-report?downline_report=true";
      await Api.get(url)
        .then((res) => {
          this.enrollmentInfo = res.data.data;
          this.series = this.getComputedSeries;
          this.getChartOptions();
        })
        .catch({})
        .finally(() => {
          this.$emit("hide-loader", "dwenrollmentByMonth");
        });
    },
    getChartOptions() {
      this.chartOptions = {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [0, 0, 0],
        },
        xaxis: {
          categories: this.getComputedCategories,
        },
        legend: {
          show: true,
          position: "top",
        },
      };
    },
  },
  computed: {
    getComputedSeries() {
      if (!this.enrollmentInfo) {
        return [];
      }
      let startYear = this.enrollmentInfo.from.split("-")[0];
      let endYear = this.enrollmentInfo.to.split("-")[0];
      let series = [];
      for (let i = startYear; i <= endYear; i++) {
        let obj = {
          name: i.toString(),
          type: "column",
          data: this.enrollmentInfo[i],
        };
        series.push(obj);
      }
      return series;
    },
    getComputedCategories() {
      return (
        this.enrollmentInfo.months ?? [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ]
      );
    },
  },
  created() {
    this.getEnrollmentReport();
  },
};
</script>