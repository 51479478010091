var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('info-chart', {
    attrs: {
      "chartSeries": _vm.memberSeries,
      "chartOptions": _vm.memberChartOptions,
      "title": "Overall Total Members"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }