var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 mb-3"
  }, [_c('div', {
    staticClass: "analytics-report"
  }, [_c('h5', [_vm._v("Enrollments By Month (Last 3 Years)")]), _c('div', {
    staticClass: "spline-chart"
  }, [_c('apexchart', {
    attrs: {
      "type": "line",
      "height": "350",
      "width": "95%",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }