var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "memberPlan",
      "centered": ""
    },
    model: {
      value: _vm.computedModal,
      callback: function callback($$v) {
        _vm.computedModal = $$v;
      },
      expression: "computedModal"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "close-btn"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('i', {
    staticClass: "fas fa-times"
  })]), _c('div', {
    staticClass: "plan-table table-responsive"
  }, [_vm.pageRecords.records.length && _vm.keyLabel && _vm.valueLabel ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.tableHeadingLeft))]), _c('th', [_vm._v("No. of Member")])])]), _c('tbody', _vm._l(_vm.pageRecords.records, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(data[_vm.keyLabel]))])]), _c('td', [_vm._v(_vm._s(data[_vm.valueLabel]))])]);
  }), 0)]) : _c('p', {
    staticClass: "text-danger"
  }, [_c('span', [_vm._v("Currently there is no data")])])]), _c('pagination', {
    attrs: {
      "rows": _vm.pageRecords
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.pageRecords = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }