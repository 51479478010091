<template>
  <div class="total-members-by-plan">
    <div class="split-title">
      <h5>Total Members By Plan</h5>
      <a href="javascript:void(0);" @click="openModal">View All</a>
    </div>
    <div class="spline-chart" v-if="memberByPlan['records']">
      <apexchart
        type="bar"
        height="150"
        :options="getComputedChartOptions"
        :series="getComputedSeries"
      ></apexchart>
    </div>
    <div v-else>
      <p>{{ dataEmptyMessage }}</p>
    </div>
    <member-plan-modal
      v-if="showModal"
      :modal.sync="showModal"
      :modalData.sync="modalData"
      keyLabel="web_display_name"
      valueLabel="total"
    />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import MemberPlanModal from "../modal/MemberPlanModal";
export default {
  name: "TotalMembersByPlan",
  components: {
    "member-plan-modal": MemberPlanModal,
  },
  data() {
    return {
      memberByPlan: {},
      showModal: false,
      dataEmptyMessage: "Currently there is no data",
      xaxisTotal: 4,
      xaxisLabelLength: 10,
      modalData:{}
    };
  },
  methods: {
    async getMemberByPlan() {
      const url = "v2/rep-analytics/member-by-plan?downline_report=true";
      await Api.get(url)
        .then((res) => {
          this.memberByPlan = res.data;
          this.modalData = res.data;
        })
        .catch(() => {
          this.memberByPlan = [];
        })
        .finally(() => {
          this.$emit("hide-loader", "dwtotalMembersByPlan");
        });
    },
    openModal() {
      this.showModal = true;
    },
  },
  created() {
    this.getMemberByPlan();
  },
  computed: {
    getComputedChartOptions() {
      let options = {
        chart: {
          height: 950,
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [0, 0, 0],
        },
        xaxis: {
          categories: [],
          labels: {
            rotate: 0,
          },
        },
        legend: {
          show: true,
          position: "top",
        },
      };
      if (!this.memberByPlan["records"]) {
        return options;
      }
      let items = this.memberByPlan.records.slice(0, this.xaxisTotal);
      options.xaxis.categories = items.map((data) => {
        let str = data.web_display_name.substring(0, this.xaxisLabelLength);
        if (data.web_display_name.length >= this.xaxisLabelLength) {
          return [
            str,
            data.web_display_name.substring(
              this.xaxisLabelLength,
              2 * this.xaxisLabelLength
            ),
          ];
        } else {
          return str;
        }
      });
      return options;
    },
    getComputedSeries() {
      if (!this.memberByPlan["records"]) {
        return [
          {
            name: "Members",
            type: "column",
            data: [],
          },
        ];
      }
      let items = this.memberByPlan.records.slice(0, this.xaxisTotal);
      return [
        {
          name: "Members",
          type: "column",
          data: items.map((data) => data.total),
        },
      ];
    },
  },
};
</script>