<template>
  <div class="total-active-by-state">
    <div class="split-title">
      <h5>Total Active By State</h5>
      <a href="javascript:void(0);" @click="openModal">View All</a>
    </div>
    <div class="spline-chart" v-if="memberByState['records']">
      <apexchart
        type="bar"
        height="150"
        :options="getComputedChartOptions"
        :series="getComputedSeries"
      ></apexchart>
    </div>
    <div v-else>
      <p>{{ dataEmptyMessage }}</p>
    </div>
    <member-plan-modal
      v-if="showModal"
      :modal.sync="showModal"
      :modalData.sync="modalData"
      keyLabel="state"
      valueLabel="total"
      tableHeadingLeft="State Code"
    />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import MemberPlanModal from "../modal/MemberPlanModal";

export default {
  name: "TotalActiveByState",
  components: {
    "member-plan-modal": MemberPlanModal,
  },
  data() {
    return {
      memberByState: {},
      showModal: false,
      dataEmptyMessage: "Currently there is no data",
      xaxisTotal: 2,
      modalData:{},
    };
  },
  methods: {
    async getMemberByState() {
      const url = "v2/rep-analytics/member-by-state?downline_report=true";
      await Api.get(url)
        .then((res) => {
          this.memberByState = res.data;
          this.modalData = res.data;
        })
        .catch(() => {
          this.memberByState = [];
        })
        .finally(() => {
          this.$emit("hide-loader", "dwtotalActiveByState");
        });
    },
    openModal() {
      this.showModal = true;
    },
  },
  created() {
    this.getMemberByState();
  },
  computed: {
    getComputedChartOptions() {
      let options = {
        chart: {
          height: 150,
          type: "line",
          stacked: false,
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [0, 0, 0],
        },
        xaxis: {
          type: "category",
          categories: [],
        },
        legend: {
          show: true,
          position: "top",
        },
      };
      let categoryArray = [];
      if (this.memberByState.records.length) {
        categoryArray = this.memberByState.records
          .slice(0, this.xaxisTotal)
          .map((data) => data.state);
      }
      options.xaxis.categories = categoryArray;
      return options;
    },
    getComputedSeries() {
      let seriesData = [];
      if (this.memberByState["records"]) {
        seriesData = this.memberByState.records
          .slice(0, this.xaxisTotal)
          .map((data) => data.total);
      }
      return [
        {
          name: "Members",
          type: "column",
          data: seriesData,
        },
      ];
    },
  },
};
</script>
