var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overallInfo-blockWrapper"
  }, [_c('div', {
    staticClass: "overallInfo-block"
  }, [_c('div', {
    staticClass: "overallInfo-content"
  }, [_vm.title ? _c('h3', [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('span', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shortDescription,
      expression: "shortDescription"
    }],
    staticClass: "total-title"
  }, [_vm._v(_vm._s(_vm.shortDescription))]), _c('div', {
    staticClass: "valueDenote"
  }, [_c('ul', _vm._l(_vm.chartOptions.labels, function (label, index) {
    return _c('li', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      key: index,
      attrs: {
        "title": label + ' ' + _vm.chartSeries[index]
      }
    }, [_c('span', {
      style: {
        backgroundColor: _vm.chartOptions.colors[index]
      }
    }), _vm._v(_vm._s(label) + "( " + _vm._s(_vm.chartSeries[index]) + " ) ")]);
  }), 0)])]), _c('apexchart', {
    staticClass: "info-donut",
    attrs: {
      "height": _vm.chartHeight,
      "type": _vm.chartType,
      "options": _vm.getComputedChartOptions,
      "series": _vm.getComputedChartSeries
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }