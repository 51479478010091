<template>
  <info-chart
    :chartSeries="overallSeries"
    :chartOptions="overallChartOptions"
    title="Overall Totals"
  />
</template>
<script>
import Api from "@/resource/Api";
import InfoChart from "@/components/RepAnalytics/InfoChart";
export default {
  name: "OverallTotalMembers",
  components: {
    InfoChart,
  },
  data() {
    return {
      overallSeries: [],
      overallChartOptions: {},
    };
  },
  methods: {
    async getTotalStats() {
      const url = "v2/rep-analytics/overall-total?downline_report=true";
      await Api.get(url)
        .then((res) => {
          let data = res.data.data;
          let [reps, employers, members, policies] = [
            data.rep ?? 0,
            data.group ?? 0,
            data.member ?? 0,
            data.plan ?? 0,
          ];
          if (data) {
            this.overallSeries = [reps, employers, members, policies];
            const [labels, colors] = [
              ["Reps", "Employers", "Members", "Policies"],
              ["#02BC77", "#2D99FF", "#F29423", "#EA584E"],
            ];
            let total = this.overallSeries.reduce(
              (next, current) => next + current,
              0
            );
            this.overallChartOptions = this.chartOptions(labels, colors, total);
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$emit("hide-loader", "dwoverallTotals");
        });
    },
    chartOptions(labels, colors, total) {
      const options = {
        labels: labels,
        plotOptions: {
          pie: {
            // size: 800,
            customScale: 1.1,
            donut: {
              size: "55%",
            },
          },
        },
        legend: {
          show: false,
        },
        dropShadow: {
          enable: false,
        },
        colors: colors,
        dataLabels: {
          enabled: false,
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: "8px",
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: function (value) {
              let percent = (value / total) * 100;
              percent = percent.toFixed(2) + "%";
              return `${value}(${percent})`;
            },
          },
        },
      };
      return options;
    },
  },
  created() {
    this.getTotalStats();
  },
};
</script>