<template>
  <div class="overallInfo-block d-block">
    <div class="overallInfo-content">
      <h3>Overall Total Policies</h3>
      <div class="valueDenote">
        <ul>
          <li v-b-tooltip.hover title="Tier: IO">
            <span class="value-active"></span> IO
          </li>
          <li v-b-tooltip.hover title="Tier: IS">
            <span class="value-withdrawn"></span> IS
          </li>
          <li v-b-tooltip.hover title="Tier: IC">
            <span class="value-termed"></span> IC
          </li>
          <li v-b-tooltip.hover title="Tier: IF">
            <span class="value-safari"></span> IF
          </li>
          <li v-b-tooltip.hover title="Total">
            <span class="value-total"></span> Total
          </li>
        </ul>
      </div>
    </div>
    <apexchart
      type="bar"
      height="300"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "OverallTotalpolicies",
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 300,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        fill: {
          colors: ["#02BC77", "#2D99FF", "#EA584E", "#F29423", "#333"],
        },

        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            columnWidth: "60%",
          },
        },
        xaxis: {
          type: "category",
          categories: ["Active", "Pending", "Termed", "Withdrawn"],
        },
        legend: {
          show: false,
        },
      },
      policyReport: [],
    };
  },
  methods: {
    async getPolicyReport() {
      const url =
        "v2/rep-analytics/tier-based-policy-report?downline_report=true";
      await Api.get(url)
        .then((res) => {
          this.policyReport = res.data.data;
          let series = [
            {
              name: "IO",
              type: "column",
              data: this.getTierData("IO"), //[active,pending,termed,withdrawn]
            },
            {
              name: "IS",
              type: "column",
              data: this.getTierData("IS"),
            },

            {
              name: "IC",
              type: "column",
              data: this.getTierData("IC"),
            },
            {
              name: "IF",
              type: "column",
              data: this.getTierData("IF"),
            },
            {
              name: "Total",
              type: "column",
              data: this.getTierData("total"),
            },
          ];
          this.series = series;
        })
        .catch(() => {
          this.policyReport = [];
        })
        .finally(() => {
          this.$emit("hide-loader", "dwoverallTotalPolicies");
        });
    },
    getCount(tier, state) {
      return this.policyReport[tier][state];
    },
    getTierData(tier) {
      let active = this.getCount(tier, "active");
      let pending = this.getCount(tier, "pending");
      let termed = this.getCount(tier, "termed");
      let withdrawn = this.getCount(tier, "withdrawn");
      return [active, pending, termed, withdrawn];
    },
  },
  created() {
    this.getPolicyReport();
  },
};
</script>
